import { toast } from 'react-toastify';
import hasIn from 'lodash/hasIn';
import remove from 'lodash/remove';
import round from 'lodash/round';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  CARD_PROCESSING_FEE,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT,
  USER_HUM_DATE_FORMAT,
  USER_TIME_FORMAT,
} from './constants';
import { useRouteLoaderData } from '@remix-run/react';

export const can = (requiredPermission, userPermission) => {
  return requiredPermission ? requiredPermission.some((p) => userPermission.includes(p)) : true;
};

export const displaynotification = (response) => {
  if (hasIn(response, 'errors')) {
    response.errors.forEach((element) => {
      toast.error(element);
    });

    return false;
  } else if (response.data.status) {
    toast.success(response.data.message);

    return true;
  } else if (!response.data.status) {
    toast.error(response.data.message);
    return false;
  }
};

export const findValueByKey = (array, key, value, returnItem) => {
  const result = array.find((item) => item[key] == value);
  return result ? result[returnItem] : '';
};

export const removeItemByKey = (array, key, value) => {
  remove(array, (item) => item[key] == value);

  return array;
};

export const pluck = (array, key) => array.map((i) => i[key]);

export const pluckAndMerge = (key) => (array) => Array.from(new Set(array.map((obj) => obj[key])));

export const flattenByKey = (arrayOfArrays, toPluck) => {
  let newArr = [];

  arrayOfArrays.forEach((item) => {
    if (item[toPluck].length > 0) {
      item[toPluck].forEach((item) => {
        newArr.push(item);
      });
    } else {
      newArr.push(item);
    }
  });

  return newArr;
};

export const pluckFromAddress = (addressArr, toPluck) => {
  let axs = addressArr.filter((add) => {
    let founded = add.types.filter((type) => type == toPluck);

    if (founded.length > 0) {
      return add;
    }
  });

  return axs.length > 0 ? axs[0] : null;
};

export const formatServerDate = (date) => {
  return dayjs(date, SERVER_DATE_FORMAT).format(USER_HUM_DATE_FORMAT);
};

export const formatServerTime = (time) => {
  dayjs.extend(customParseFormat);

  return dayjs(time, 'HH:mm').format(USER_TIME_FORMAT);
};

export const formatServerDateTime = (dateTime) => {
  return dayjs(dateTime, SERVER_DATE_FORMAT + ' ' + SERVER_TIME_FORMAT).format(
    USER_HUM_DATE_FORMAT + ' ' + USER_TIME_FORMAT,
  );
};

export const addDuration = (time, duration) => {
  dayjs.extend(customParseFormat);

  duration = duration.split(':');

  return dayjs(time, 'HH:mm')
    .add(duration[0], 'hour')
    .add(duration[1], 'minute')
    .format(USER_TIME_FORMAT);
};

export const isPreviousCompatible = (currentSchedule, previousSchedule, index, all_durations) => {
  if (index > 0 && previousSchedule !== undefined && all_durations.length > 0) {
    let currentVisitTime = currentSchedule.visit_time;

    dayjs.extend(customParseFormat);
    dayjs.extend(isSameOrBefore);

    let duration = previousSchedule.duration.split(':');

    let departureFromPreviousLoc = dayjs(previousSchedule.visit_time, 'HH:mm')
      .add(duration[0], 'hour')
      .add(duration[1], 'minute')
      .format('HH:mm');
    let currentDistanceInfo = all_durations[index - 1];

    let timeInSeconds = currentDistanceInfo.duration.value;

    let timeInMinutes = round(timeInSeconds / 60);

    let nextLocationArrival = dayjs(departureFromPreviousLoc, 'HH:mm')
      .add(timeInMinutes, 'minutes')
      .format('HH:mm');

    let res = dayjs(nextLocationArrival, 'HH:mm').isSameOrBefore(dayjs(currentVisitTime, 'HH:mm'));

    let directionLink = `https://www.google.com/maps/dir/${previousSchedule.lat},${previousSchedule.lng}/${currentSchedule.lat},${currentSchedule.lng}`;
    if (res) {
      return {
        status: true,
        message: 'you are good to go',
        nextArrival: dayjs(nextLocationArrival, 'HH:mm').format(USER_TIME_FORMAT),
        directionLink,
      };
    } else {
      return {
        status: false,
        message: `Can not reach at this destination in time, The  earliest can reach is at ${dayjs(
          nextLocationArrival,
          'HH:mm',
        ).format(USER_TIME_FORMAT)}`,
        nextArrival: dayjs(nextLocationArrival, 'HH:mm').format(USER_TIME_FORMAT),
        directionLink,
      };
    }
  }

  return {
    status: true,
    message: 'you are good to go',
  };
};

export const sanitizeNameForUpload = (name) => {
  const uniqueString = Date.now().toString();
  let sanitized = name.replaceAll(/[\\-\\+\\^\]\\#["{}%~<>,]/g, '');
  sanitized = sanitized.replace(/ /g, '_');
  return uniqueString + '_' + sanitized;
};

export const formatPrice = (price, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(price);
};

export const getNextPage = (totalPages, currentPage = null) => {
  if (currentPage) {
    //get the next true key from pages object after the currentPage key
    let nextKey = Object.keys(totalPages).findIndex((key) => key === currentPage) + 1;
    let nextPage = Object.keys(totalPages).find((key, index) => {
      if (index >= nextKey) {
        return totalPages[key] === true;
      }
    });

    return nextPage ? nextPage : 'confirm-order';
  } else {
    let nextPage = Object.keys(totalPages).find((key) => totalPages[key] === true);

    return nextPage ? nextPage : 'confirm-order';
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return null;
};

export const formatPhoneNumberWeb = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return null;
};

export const constructListingTitle = (listing) => {
  let title = '';

  if (listing.address) {
    title += `${listing.address}`;
  }

  if (listing.unit) {
    title += ` #${listing.unit}`;
  }

  if (listing.city) {
    title += `, ${listing.city}`;
  }

  if (listing.state) {
    title += `, ${listing.state}`;
  }

  if (listing.zip_code) {
    title += ` ${listing.zip_code}`;
  }

  return title;
};

export const renderCompleteDateTime = (date, time) => {
  return `${formatServerDate(date)} - ${formatServerTime(time)}`;
};

export const checkPermission = (requiredPermission) => {
  const dashboardLoader = useRouteLoaderData('routes/__panel/dashboard');

  const userPermissions = dashboardLoader.user.permissions || [];

  return Object.values(userPermissions).includes(requiredPermission);
};

export const returnMediaType = (type) => {
  let types = type.split('\\');

  return types[types.length - 1];
};

export const checkLoading = (fetcher) => {
  return fetcher.state === 'submitting' || fetcher.state === 'loading';
};

export const calculateCardFee = (amount) => {
  return (amount * CARD_PROCESSING_FEE) / 100;
};

export const getNameInitials = (firstName, lastName) => {
  return firstName.charAt(0) + lastName.charAt(0);
};

export const trimText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

export const trimServiceDescription = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return {
      text: words.slice(0, wordLimit).join(' ') + '...',
      isTrimmed: true,
    };
  }
  return {
    text: text,
    isTrimmed: false,
  };
};

export const showWatermark = (is_order_paid, agentPayBefore, allow_download) => {
  let showWaterMark = true;

  if (allow_download) {
    showWaterMark = false;
  } else {
    if (agentPayBefore && !is_order_paid) {
      showWaterMark = true;
    } else {
      showWaterMark = false;
    }
  }

  return showWaterMark;
};

export const isValidInput = (name, value) => {
  if (value && value.length > 0) {
    return true;
  } else {
    toast.error(`Please enter a valid ${name}.`);
    return false;
  }
};

export const snakeCaseToSpace = (name) => {
  return name.replace(/_/g, ' ');
};

export const isLessThan24Hours = (visitDate, visitTime) => {
  // Combine the visit date and time into a single dayjs object
  const visitDateTime = dayjs(`${visitDate}T${visitTime}`);

  // Get the current date and time
  const now = dayjs();

  // Calculate the difference in hours
  const differenceInHours = visitDateTime.diff(now, 'hour');

  // Check if the visit date-time is less than 24 hours from now
  return differenceInHours < 24 && differenceInHours >= 0;
};

export const hasVisitPassed = (visitDate, visitTime) => {
  // Combine the visit date and time into a single dayjs object
  const visitDateTime = dayjs(`${visitDate}T${visitTime}`);

  // Get the current date and time
  const now = dayjs();

  // Check if the visit date-time has passed
  return visitDateTime.isBefore(now);
};
